import { cateringRequest, cateringRequestHide, cateringUploadsHide } from '@/utils/fetch'

interface costList {
  type_id?: string // 报销类型
  money?: string // 金额（元）
  date?: string // 时间
  explain?: string // 说明
}
interface saveReq {
  total_money?: string | number // 全部金额
  pics: string // 报销图片
  project_id: string // 项目id
  details: Array<costList> //
}
// export type saveProject = saveReq //导出请求保存的数据结构

// 费用申请 添加接口
export function FeeSave(data: saveReq) {
  const url = '/api/fee/save'
  return cateringRequest.post(url, data)
}

//报销类型

export function getPubFeeType() {
  const url = '/api/pub/fee-type'
  return cateringRequestHide.get(url)
}

